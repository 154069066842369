<template>
  <component
    :is="component"
  />
</template>

<script>
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Marketplace',
  components: {
    NewDeals: lazyLoad('views/Marketplace/NewDeals/Index'),
    Trade: lazyLoad('views/Marketplace/Trade/Index'),
  },
  data() {
    return {
      component: 'NewDeals',
    };
  },
};
</script>
